html {
  font-family: 'Open Sans', sans-serif;;
  font-weight: 400;
  font-size: 100%;
}

body {
  background-color: black;  /* #222831; */
  color: #cccccc;
  margin: 0;
  height: 100%;
}

.brand {
  font-family: 'Libre Baskerville', serif;
  /* font-weight: bold; */
  color: #d65a31;
}

.article-body {
  height: 100vh;
  display: grid;
  grid-template-rows: 80px 1fr 54px;
  justify-content: stretch;
}

header {
  padding: 12px;
  background-color: #222831;
  /* background-color: #393e46; */
}

main {
  padding: 24px 0px 12px 12px;
  display: grid;
  grid-template-rows: 1fr;
  overflow: hidden;
}

.header-brand {
  font-size: 1.5rem;
  color: #eeeeee !important;
}

footer {
  padding: 8px;
  background-color: #393e46;
}


.section-room {
  overflow: hidden;
}

.section-messages {
  display: flex;
  flex-direction: column-reverse;
  justify-content: end;
  overflow-y: scroll;
}

.hyperlink {
  cursor: pointer;
  color: #7195ce;
  text-decoration: underline;
}

.hyperlink:hover {
  color: #b5deff;
}

.hyperlink-you {
}

.hyperlink-player {
}

.hyperlink-npc {
}

.hyperlink-exit {
}

.main-message {
  max-width: 40rem;
}

/* Message <Action/> */
.main-message-action {
  color: #f39422;
}

.main-message-action.historical {
  color: #999999;
}

.main-message-action.historical .hyperlink  {
  text-decoration: none;
  color: #999999;
}

/* Message <System/> */
.main-message-system {
  color: lightgrey;
}

/* Message <Dialog/> */
.main-message-dialog {
  color: aqua;
}

.main-message-dialog.historical {
  color: #999999;
}

/* Message <Description/> */
.main-message-description {
  font-size: 1.1rem;
  line-height: 1.5rem;
}

.main-message-description.background {
  font-style: italic;
  font-size: 1rem;
  color: #b5deff;
}

.main-message-description.historical {
  color: #999999;
}

.main-message-description.historical .hyperlink  {
  text-decoration: none;
  color: #999999;
}

/* Message <Room/> */
.main-message-room {
  margin-top: 24px;
}

.main-message .room-name {
  font-family: 'Libre Baskerville', serif;
  font-weight: bold;
  text-transform: uppercase;
  color: #eeeeee; /*#d4927c;*/
  margin: 0px 8px 0px 0px;
}

.main-message .room-description {
  
}

.main-message .room-exits {
  font-size: 1rem;
  color: #cab8ff;
}

.main-message-room.historical {
  font-size: 1rem;
  color: #999999;
}

.main-message-room.historical .room-name {
  font-weight: normal;
  color: #999999;
}

.main-message-room.historical .room-exits {
  color: #999999;
}

.main-message-room.historical .hyperlink  {
  text-decoration: none;
  color: #999999;
}

.room-name {
  font-weight: bold;
  text-transform: uppercase;
  color: #d4927c;
  margin: 0px 8px 0px 0px;
}

.room-area {
  font-size: smaller;
  text-transform: uppercase;
}

.room-summary {
}

.room-players {
  line-height: 1.6rem;
  color: #f39422;
}

.command-input {
  background-color: #222831;
  color: #eeeeee;
}

.command-input:focus {
  background-color: #222831;
  color: #eeeeee;
  border-color: #d65a31;
  box-shadow: 0 0 0 0.25rem rgb(214 90 49 / 25%)
}